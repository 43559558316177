import "../style/index.scss";
import "./jquery-ui.min.js";
import "./modernizr.js";

'use strict';

var menuTrigger = document.getElementById('menuTrigger');
var menu = document.getElementById('menu');
var topMenuItems = document.querySelectorAll('.menu__item');

setTimeout(function () {
  
  if ($(".preloader").length) {
    $(".preloader").hide();
    $(".hiddenAsync").removeClass("hiddenAsync");
  }

  menu = [];
  $("#menu a").each(function(i) {
      var thisLink = $(this);
      var thisId = thisLink.attr('href');
      var thisTarget = $(thisId);
      menu.push({
          'anchor': thisLink,
          'id': thisId,
          'target': thisTarget
      });
      thisLink.on('click', function(e) {
          e.preventDefault();
          $('html, body').animate({
              scrollTop: thisTarget.offset().top
          }, 800);
      });
  });

  $(document).ready(function() {
    var hash = window.location.hash;
    if(window.location.hash != ''){ 
      scrollTo($(hash), 200);
    }
    else {}
  });

  var footerOffsetTop = footerOffset(),
      $scrolltop = $(".scrolltop");
  $scrolltop.on("click", function () {
    scrollTo($(".top-line"), 300);
  });
  $(window).on("scroll", function () {
    if ($(window).scrollTop() >= footerOffsetTop) {
      $scrolltop.addClass("scrolltop-end");
    } else {
      $scrolltop.removeClass("scrolltop-end");
    }
    if ($(window).scrollTop() >= 450) {
      $scrolltop.addClass("active");
    } else {
      $scrolltop.removeClass("active");
    }
  }).on("load resize", function () {
    footerOffsetTop = footerOffset();
  });
}, 3000);
$(".menu-trigger").on("click", function () {
  $(".menu-trigger, .menu").toggleClass("active");
  $("html").toggleClass("fixed");
});
$(".nav-link").on("click", function (e) {
  e.preventDefault();
  var $this = $(this),
      sectionId = $this.attr("href");

  try {
    $(".menu-trigger, .menu").removeClass("active");
    $("html").removeClass("fixed");
    scrollTo($(sectionId), 300);
    location.hash = sectionId;
  } catch (e) {
    console.error(e);
  }
});
$(".footer-nav__item.info .footer-nav__link").on("click", function (e) {
  e.preventDefault();
  var sectionId = $(this).attr("href");
  $(sectionId).toggleClass("visible");

  if ($(sectionId).hasClass('visible')) {
    scrollTo($(sectionId), 300);
  }
});
$(".form__input").on('focus change blur', function () {
  focused($(this), 'selected');
}).each(function () {
  focused($(this), 'selected');
});
$(".location-trigger, .location").on("click", function () {
  $("#location-popup").fadeToggle(200);
  scrollTo($(".top-line"), 300);
});
$(".popup__close, #location-popup-ok").on("click", function () {
  $("#location-popup").fadeOut(200);
}); // скрыть попап при клике вне

$(document).mouseup(function (event) {
  outEvent(event, $("#location-popup"), function () {
    $(this).fadeOut(200);
    $('#location-city').val('').removeClass('selected').next().removeClass('selected');
  });
}); // запустить callback при событии, 
// произошедшем вне DOMElement

$('#location-city').autocomplete({
  source: '/api/v1/get_city_autocomplete',
  minLength: 2,
  select: function(event, ui) {
    $("#location-popup").fadeOut(200); //закрываем попап
    $(this).val('');

    $('.location__city').text(ui.item.value); //перезаписываем выбранный город в меню
    $('.popup__title').text(ui.item.label); //перезаписываем выбранный город в меню

    //отправляем на сервер запрос, чтобы запомнить город
    $.ajax({
      type: 'POST',
      url: '/api/v1/confirm_location',
      data: 'city_id=' + ui.item.id,
      complete: function () {
          $(location).attr("href", "/");
      },
    });
  },
  open: function open(event, ui) {
    // рассчитываем ширину выпадающего списка
    var listWidth = $(window).innerWidth() < 660 ? $(this).innerWidth() : 400;
    $(".ui-menu").css({
      maxWidth: listWidth + 'px'
    }); // убираем скролл, когда список открыт

    $('html').addClass('fixed');
  },
  close: function close(event, ui) {
    $('html').removeClass('fixed');
  }
});

function outEvent(event, DOMElement, callback) {
  if ( // если событие произошло вне DOMElement
  !DOMElement.is(event.target) // и не в его дочерних элементах
  && DOMElement.has(event.target).length === 0) {
    // выполнить callback для DOMElement
    callback.apply(DOMElement, arguments);
  }
}

function scrollTo($el, duration) {
  if ($el) {
    try {
      $("html, body").stop().animate({
        scrollTop: $el.offset().top
      }, duration);
    } catch (e) {
      console.error(e);
    }
  }
}

function footerOffset() {
  return Math.round($("footer").offset().top - $(window).innerHeight());
}

function focused($input, selectClass) {
  if ($input.val().trim().length) {
    $input.addClass(selectClass);
    $input.next().addClass(selectClass);
  } else {
    $input.removeClass(selectClass);
    $input.next().removeClass(selectClass);
  }
}

function toggleOffersLoading(turnOn) {
  $(".preloader").toggle(turnOn);
  $("#async").toggleClass("hiddenAsync", turnOn);
}
